import React, { FunctionComponent, InputHTMLAttributes } from "react";
import styled from "styled-components";

import { Device } from "../../../utils/device";
import colours from "../../../utils/colours";

interface RxCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  isChecked: boolean;
  labelText: string | React.ReactNode;
  checkColour?: string;
  error?: boolean;
  errorRed?: boolean;
  labelColour?: string;
  labelGap?: number;
  labelSize?: "small";
  labelWeight?: string;
  subLabel?: string;
}

const RxCheckbox: FunctionComponent<RxCheckboxProps> = (props) => {
  return (
    <S.Container data-cy={`${props.name}-checkbox`}>
      <S.Input
        colour={props.checkColour}
        type="checkbox"
        name={props.name}
        id={props.name}
        checked={props.isChecked}
        {...props}
      />
      <S.LabelContainer $labelGap={props.labelGap}>
        <S.Label
          htmlFor={props.name}
          $hasSubLabel={!!props.subLabel}
          labelSize={props.labelSize}
          labelWeight={props.labelWeight}
          colour={props.labelColour}
        >
          {props.labelText}
        </S.Label>
        {props.subLabel && (
          <S.SubLabel htmlFor={props.name} $labelGap={props.labelGap}>
            {props.subLabel}
          </S.SubLabel>
        )}
      </S.LabelContainer>
    </S.Container>
  );
};

interface StyleProps {
  $labelGap?: number;
  $hasSubLabel?: boolean;
  colour?: string;
  labelSize?: "small";
  labelWeight?: string;
  errorRed?: boolean;
}

const S = () => {};

S.Container = styled.div`
  display: flex;
  align-items: center;
`;

S.Input = styled.input<StyleProps>`
  width: 3rem;
  height: 3rem;
  background: ${(p) =>
    p.checked ? p.colour || colours.pillTeal : colours.white};
  color: ${(p) => p.colour};
  box-shadow: inset 0 0 0 0.4rem ${colours.white};
  border-radius: 4px;
  border: 1px solid ${colours.shellOverlay};
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex-shrink: 0;
`;

S.LabelContainer = styled.label<StyleProps>`
  /* justify-content: center; */
  padding-left: ${(p) => p.$labelGap || 10}px;
`;

S.Label = styled.label<StyleProps>`
  font-size: 1.4rem;
  font-weight: ${(p) => (p.$hasSubLabel ? 800 : 400)};
  color: ${(p) => p.colour};
  cursor: pointer;

  @media (min-width: ${Device.mobile}) {
    font-size: 1.6rem;
  }

  @media (min-width: ${Device.tablet}) {
    font-size: 1.8rem;
  }

  ${(p) =>
    p.labelSize === "small" &&
    `
    font-size: 1rem;

    @media(min-width: ${Device.mobile}) {
      font-size: 1.2rem;
    }
  `}
`;

S.SubLabel = styled.label<StyleProps>`
  font-size: 1.2rem;
  display: block;
  padding-left: ${(p) => p.$labelGap || 10}px;
  padding-top: 5px;
  width: 80%;
  cursor: pointer;

  @media (min-width: ${Device.mobile}) {
    font-size: 1.6rem;
  }
`;

export default RxCheckbox;
