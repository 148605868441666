import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface RxErrorProps {
  message: string;
}

const RxError: FunctionComponent<RxErrorProps> = (props) => {
  return (
    <S.ErrorMessage role="alert" data-cy="error-message">
      {props.message}
    </S.ErrorMessage>
  );
};

const S = () => {};

S.ErrorMessage = styled.li`
  font-size: 1.4rem;
  font-weight: 500;
  margin: 2px 0;
`;

export default RxError;
