import { ValidationErrorItem } from "joi";

export const getJoiErrorMessage = (
  fieldName: string,
  errors: ValidationErrorItem[] | null = null
): string | null => {
  if (errors === null || !Array.isArray(errors) || errors.length === 0) {
    return null;
  }

  const index = errors.findIndex(function (error) {
    return error.context?.label.indexOf(fieldName) === 0;
  });

  if (index !== -1) {
    return getAliasErrorMessage(fieldName, errors[index]);
  }

  return null;
};

const getAliasErrorMessage = (
  fieldName: string,
  error: ValidationErrorItem
): string => {
  if (!errorAliases[fieldName] || !errorAliases[fieldName][error.type]) {
    return error.message;
  }

  return errorAliases[fieldName][error.type];
};

type errorAlias = {
  [key: string]: { [key: string]: string };
};

const errorAliases: errorAlias = {
  agreedToPledge: {
    "boolean.base": "Please accept the pledge",
    "boolean.required": "Please accept the pledge",
    "boolean.false": "Please accept the pledge",
    "any.required": "Please accept the pledge",
    "any.invalid": "Please accept the pledge",
  },
  contact_method: {
    "string.base": "Please select your preferred contact method",
    "string.empty": "Please select your preferred contact method",
    "any.required": "Please select your preferred contact method",
    "any.invalid": "Please select your preferred contact method",
  },
  enquire_for_self: {
    "boolean.base":
      "Please select one of the enquiry options (yourself or company)",
    "boolean.required":
      "Please select one of the enquiry options (yourself or company)",
    "boolean.false":
      "Please select one of the enquiry options (yourself or company)",
    "any.required":
      "Please select one of the enquiry options (yourself or company)",
    "any.invalid":
      "Please select one of the enquiry options (yourself or company)",
  },
  enquire_for_company: {
    "boolean.base":
      "Please select one of the enquiry options (yourself or company)",
    "boolean.required":
      "Please select one of the enquiry options (yourself or company)",
    "boolean.false":
      "Please select one of the enquiry options (yourself or company)",
    "any.required":
      "Please select one of the enquiry options (yourself or company)",
    "any.invalid":
      "Please select one of the enquiry options (yourself or company)",
  },
  bio: {
    "string.base": "Please provide your bio",
    "string.empty": "Please provide your bio",
    "string.max": "Please provide your bio",
    "any.required": "Please provide your bio",
  },
  careerGoals: {
    "string.base": "Incorrectly set career goals",
    "array.unique": "You cannot select the same career goal more than once",
    "any.required": "A minimum of 3 career goals is required",
    "any.only": "A minimum of 3 career goals is required",
    "array.base": "A minimum of 3 career goals is required",
    "array.min": "Please select between 3 and 6 career goals",
    "array.max": "Please select between 3 and 6 career goals",
  },
  city: {
    "string.base": "Please provide your city",
    "string.max": "Please provide your city",
    "string.empty": "Please provide your city",
    "any.required": "Please provide your city",
  },
  company: {
    "string.base": "Please provide your company",
    "string.max": "Please provide your company",
    "string.empty": "Please provide your company",
    "any.required": "Please provide your company",
  },
  jobCompany: {
    "string.base": "Please provide your company",
    "string.empty": "Please provide your company",
    "string.max": "Please provide your company",
    "any.required": "Please provide your company",
  },
  consent: {
    "any.required": "Please consent before submitting this form",
    "any.only": "Please consent before submitting this form",
  },
  dateOfBirth: {
    "date.base": "Please provide your date of birth",
    "date.required": "Please provide your date of birth",
    "date.format": "Please provide your date of birth",
    "date.min": "Please provide your date of birth",
    "date.max": "You must be over 18 to join AllBright",
  },
  decision_maker: {
    "any.required": "Are you a key decision maker?",
    "any.only": "Are you a key decision maker?",
  },
  first_name: {
    "string.base": "Please provide your first name",
    "string.empty": "Please provide your first name",
    "any.required": "Please provide your first name",
  },
  last_name: {
    "string.base": "Please provide your last name",
    "string.empty": "Please provide your last name",
    "any.required": "Please provide your last name",
  },
  phone_number: {
    "string.base": "Please provide your phone number",
    "string.empty": "Please provide your phone number",
    "string.min": "Your phone number must contain more than 8 digits",
    "string.max": "Your phone number must contain less than 14 digits",
    "string.pattern.base": "Your phone number is invalid",
    "any.required": "Please provide your phone number",
  },
  email: {
    "string.base": "Please provide your email",
    "string.empty": "Please provide your email",
    "string.email": "Email provided is invalid",
    "any.required": "Please provide your email",
  },
  ethnicGroups: {
    "object.base": "Please provide your race/ethnicity",
    "array.base": "Please provide your race/ethnicity",
    "any.required": "Please provide your race/ethnicity",
    "array.min": "Please provide your race/ethnicity",
  },
  fundingSource: {
    "string.base": "Please provide your funding source",
    "string.empty": "Please provide your funding source",
    "string.max": "Please provide your funding source",
    "any.required": "Please provide your funding source",
  },
  goals: {
    "any.required": "Please select at least one goal",
    "array.base": "Please select at least one goal",
    "array.min": "Please select at least one goal",
    "array.max": "Please select up to three goals",
    "any.only": "Please select between one and three goals",
  },
  imageSrc: {
    "string.base": "Please provide a valid image",
    "string.empty": "Please provide a valid image",
    "string.max": "Please provide a valid image",
    "any.required": "Please provide a valid image",
  },
  jobIndustry: {
    "string.base": "Please provide your industry",
    "string.empty": "Please provide your industry",
    "string.max": "Please provide your industry",
    "any.required": "Please provide your industry",
  },
  interests: {
    "any.required": "Please select at least one interest",
    "array.base": "Please select at least one interest",
    "array.min": "Please select at least one interest",
  },
  jobLevel: {
    "string.base": "Please provide your job level",
    "string.empty": "Please provide your job level",
    "string.max": "Please provide your job level",
    "any.required": "Please provide your job level",
  },
  jobTitle: {
    "string.base": "Please provide your job title",
    "string.empty": "Please provide your job title",
    "string.max": "Please provide your job title",
    "any.required": "Please provide your job title",
  },
  job_title: {
    "string.base": "Please provide your job title",
    "string.empty": "Please provide your job title",
    "any.required": "Please provide your job title",
  },
  job_company: {
    "string.base": "Please provide your company",
    "string.empty": "Please provide your company",
    "any.required": "Please provide your company",
  },
  jobStatus: {
    "string.base": "Please provide your job status",
    "string.empty": "Please provide your job status",
    "string.max": "Please provide your job status",
    "any.required": "Please provide your job status",
  },
  organisationSize: {
    "string.base": "Please provide the size of your organisation",
    "string.empty": "Please provide the size of your organisation",
    "string.max": "Please provide the size of your organisation",
    "any.required": "Please provide the size of your organisation",
  },
  salary: {
    "string.base": "Please provide your salary",
    "string.empty": "Please provide your salary",
    "string.max": "Please provide your salary",
    "any.required": "Please provide your salary",
  },
  title: {
    "string.base": "Please provide your title",
    "string.empty": "Please provide your title",
    "any.required": "Please provide your title",
  },
  membership_type: {
    "string.base":
      "Please select the type of membership you wish to enquire about",
    "string.empty":
      "Please select the type of membership you wish to enquire about",
    "string.max":
      "Please select the type of membership you wish to enquire about",
    "any.required":
      "Please select the type of membership you wish to enquire about",
    "any.only":
      "Please select the type of membership you wish to enquire about",
    "array.base":
      "Please select the type of membership you wish to enquire about",
  },
  membership_interest: {
    "string.base": "Please select your membership of interest",
    "string.empty": "Please select your membership of interest",
    "string.max": "Please select your membership of interest",
    "any.required": "Please select your membership of interest",
    "any.only": "Please select your membership of interest",
    "array.base": "Please select your membership of interest",
  },
  programme_type: {
    "string.base": "Please select which programme you are interested in",
    "string.empty": "Please select which programme you are interested in",
    "string.max": "Please select which programme you are interested in",
    "any.required": "Please select which programme you are interested in",
    "any.only": "Please select which programme you are interested in",
    "array.base": "Please select which programme you are interested in",
  },
  alliance_contact_reason: {
    "string.base": "Please select how you heard about us",
    "string.empty": "Please select how you heard about us",
    "string.max": "Please select how you heard about us",
    "any.required": "Please select how you heard about us",
    "any.only": "Please select how you heard about us",
    "array.base": "Please select how you heard about us",
  },
  other_membership_interest: {
    "string.base": "Please state your membership of interest",
    "string.empty": "Please state your membership of interest",
    "string.max": "Please state your membership of interest",
    "any.required": "Please state your membership of interest",
  },
  funding_source: {
    "string.base": "Please provide your funding source",
    "string.max": "Please provide your funding source",
    "string.empty": "Please provide your funding source",
    "any.required": "Please provide your funding source",
  },
  default: {
    "any.only": "An error occurred",
    "any.required": "An error occurred",
    "string.base": "An error occurred",
    "string.empty": "An error occurred",
    "string.pattern.base": "An error occurred",
  },
};
